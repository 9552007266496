import moment from 'moment'
import VueTagsInput from '@johmun/vue-tags-input'
import EventBus from '@/util/EventBus'
import State from '@/util/State'
import Campaign from '@/models/Campaign'
import { ChannelType } from '@/models/ChannelType'
import { SendingStatuses } from '@/models/SendingStatuses'
import { CampaignType } from '@/models/CampaignType'
import { ChannelSms } from '@/models/ChannelSms'
import ComponentStatusStore from '@/models/ComponentStatusStore'
import { SaveBarProperties } from '@/models/SaveBarProperties'
import { PhonesWithPrefix as PhonesWithPrefixClass } from '@/models/PhonesWithPrefix'
import { CurrentSms } from '@/classes/CurrentSms'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { Landing } from '@/components/Template/LandingEditor/classes/Landing'
import { SmsComponentsData } from '@/classes/componentsData/SmsComponentsData'
import { SmsPreview } from '@/classes/preview/SmsPreview'
import groupService from '@/services/group.service'
import CampaignService from '@/services/campaign.service'
import LandingTemplateService from '@/services/landing-template.service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import SendLater from '@/components/SendLater/SendLater.vue'
import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import ContactSource from '@/components/ContactSource/ContactSource.vue'
import AiAssistant from '@/components/AiAssistant/AiAssistant.vue'
import PricesByCountry from '@/components/Modals/PricesByCountry/PricesByCountry.vue'
import FirstSection from '@/components/FirstSection/FirstSection.vue'
import MeetOptions from '@/components/MeetOptions/MeetOptions.vue'
import BirthdayOptions from '@/components/BirthdayOptions/BirthdayOptions.vue'
import TestModal from '@/components/Modals/TestModal/TestModal.vue'
import BalanceAlert from '@/components/Modals/BalanceAlert/BalanceAlert.vue'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import ImportFile from '@/components/ContactSource/ImportFile/ImportFile.vue'
import ImportManual from '@/components/ContactSource/ImportManual/ImportManual.vue'
import ImportGroups from '@/components/ContactSource/ImportGroups/ImportGroups.vue'
import ChangeSourceDialog from '@/components/ContactSource/ChangeSourceDialog/ChangeSourceDialog.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import LandingEditor from '@/components/Template/LandingEditor/LandingEditor.vue'
import CampaingHeaderTitle from '../CampaingHeader/CampaingHeaderTitle/CampaingHeaderTitle.vue'
import CampaignPreview from '../CampaignPreview/CampaignPreview.vue'
import SecondSection from './SecondSection/SecondSection.vue'
import MessagePreview from './MessagePreview/MessagePreview.vue'
import AdvancedOptions from './AdvancedOptions/AdvancedOptions.vue'
import getEnv from '@/util/env'
import UnsuscribeService from '@/services/unsuscribe.service'
import UnsubscribeEditor from '@/components/UnsubscribeEditor/UnsubscribeEditor.vue'
import FormsEditor from '@/components/FormsEditor/FormsEditor.vue'
import FormPreview from '@/components/FormPreview/FormPreview.vue'
import FormDataTable from '@/classes/Form/FormDataTable.js'
import UrlPreview from '@/components/UrlPreview/UrlPreview.vue'
import UrlsDialog from '@/views/Pages/Tools/Urls/UrlsDialog/UrlsDialog.vue'
import UsersUrlsService from '@/services/users-urls.service'
import { TrackingUrl } from '@/models/TrackingUrl'
import VerifiedSmsService from '@/services/verified-sms.service'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import CampaignModalExit from '../CampaignModalExit/CampaignModalExit.vue'
import ModalValidateSend from '../ModalValidateSend/ModalValidateSend.vue'
import { SmsRegExp } from '@/classes/SmsRegExp'
import CampaignCosts from '@/models/CampaignCosts'
import CountryService from '@/services/country.service'
import { sha256 } from '@/util/SHA256'
import UserService from '@/services/user.service'

export default {
  name: 'CampaignSms',
  components: {
    HeaderTopDashboard,
    FirstSection,
    SecondSection,
    SendLater,
    VueTagsInput,
    ShippingMode,
    ContactSource,
    PricesByCountry,
    MessagePreview,
    AdvancedOptions,
    MeetOptions,
    BirthdayOptions,
    TestModal,
    BalanceAlert,
    CampaingHeaderTitle,
    CampaignPreview,
    LandingPreview,
    OverlayManager,
    LandingEditor,
    MultiSelect,
    ImportFile,
    ImportManual,
    ImportGroups,
    ChangeSourceDialog,
    FormsEditor,
    FormPreview,
    UnsubscribeEditor,
    UrlPreview,
    UrlsDialog,
    VideoPlayer,
    CampaignModalExit,
    ModalValidateSend,
    AiAssistant,
  },
  data: function () {
    return {
      currentSms: new CurrentSms('GSM', 0, 1, 0),
      showEditor: false,
      isLandingMessage: false,
      showLanding: false,
      showForm: false,
      showUrl: false,
      showUnsubscribe: false,
      formPreviewUrl: null,
      urlPreviewUrl: null,
      urlId: null,
      unsubscribePreview: null,
      landing: { id: null, name: '', preview: '' },
      landingTemplate: new Landing(null),
      formValid: false,
      saveBarProperties: new SaveBarProperties(),
      drawer: null,
      panel: [],
      modalPrices: false,
      componentsData: new SmsComponentsData(),
      campaign: new Campaign(new ChannelSms()),
      preview: new SmsPreview(),
      contactsFileUploadResult: new ContactsFileUploadResponse(),
      channelType: ChannelType.SMS_ID,
      user: null,
      urls: {
        redirectUrl: '/',
        preview: 'campaign/sms/preview',
        store: 'sms/store',
        send: 'sms/send',
        update: 'sms/update',
        paymentUrl: 'payment',
        submitUrl: '',
      },
      fields: {
        availableFields: {},
        availableDateFields: {},
        currentFieldsMessage: [],
        currentUrlFieldsMessage: [],
      },
      options: new ComponentStatusStore(false, false, 'now'),
      regex: SmsRegExp.getRegExp(),
      emptyRecipients: false,
      contactsSourceErrors: {
        show: false,
        textContent: '',
        type: 'error',
      },
      phonesWithPrefix: new PhonesWithPrefixClass(),
      test: {
        phone: '',
        phonePrefix: '',
        testContacts: [],
      },
      sendTestDialog: false,
      readyStatus: {
        ready: false,
      },
      editorModeSaving: false,
      multiSelectHeaders: {
        name: { header: this.$t('Grupo') },
        // sendings: { header: this.$t('Envíos'), help: this.$t('Número de veces que se realizaron envíos a un grupo') },
        contacts: { header: this.$t('Contactos'), align: 'center' },
      },
      nextGroupsPage: 1,
      lastGroupsPage: 10,
      groupsTotal: 0,
      selectedGroups: [],
      sourceKeys: {
        IMPORT_KEY: 'import',
        CONTACTS_KEY: 'contacts',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
      },
      changeRecipientsSource: false,
      nextRecipientsSource: null,
      state: new State('state', ['urlCampaignExit']),
      showFormEditor: false,
      showFormPreviewFlag: true,
      formDataTable: new FormDataTable(),
      showUnsubscribePreviewIframe: false,
      editUrlDialog: false,
      trackingUrl: new TrackingUrl(),
      resetUrlPreviewFlag: true,
      toggleVideoPlayer: false,
      readySecondSection: true,
      showCampaignExit: false,
      urlCampaignExit: '',
      confirmCampaignExit: false,
      routeLeave: true,
      showValidateModal: false,
      loadingValidateModal: false,
      countryIsoValidateSms: [
        'IN', 'IND',
      ],
      lastCampaignHash: null,
      isSendingTest: false,
      isSendingCampaign: false,
      unsubscribePageId: undefined,
      resetUnsubscribe: false,
      aiDrawerCtrl: {
        show: false,
      },
      showTheme: false,
    }
  },
  watch: {
    'campaign.countryIso': function () {
      if (['CO', 'BR'].indexOf(this.campaign.countryIso) >= 0) {
        this.campaign.sendings[0].channel.sender = 'Test'
      }
    },
  },
  methods: {
    switchTheme () {
      this.showTheme = !this.showTheme

      if (this.showTheme) {
        document.body.classList.remove('main-theme')
        document.body.classList.add('test-theme')
      } else {
        document.body.classList.remove('test-theme')
        document.body.classList.add('main-theme')
      }
    },
    setAiIdea (idea) {
      this.campaign.sendings[0].channel.message = idea
      EventBus.$emit('CampaignSms:parseSms')
    },
    openVideoPlayer () {
      let videoSms = {}
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 1)
      }
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic && this.$router.currentRoute.query.landing !== undefined) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 5)
      }
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic && this.$router.currentRoute.query.poll !== undefined) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 8)
      }
      if (this.campaign.subtype === CampaignType.automatic.subtypes.meet) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 2)
      }
      if (this.campaign.subtype === CampaignType.automatic.subtypes.birthday) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 3)
      }

      this.$store.dispatch('videoPlayer/toggle', {
        show: true,
        url: videoSms.url,
        title: videoSms.title,
      })
    },
    resetUrlPreview () {
      this.resetUrlPreviewFlag = false
      setTimeout(() => { this.resetUrlPreviewFlag = true })
    },
    prepareEditUrlDialog () {
      UsersUrlsService.getUrlById(this.urlId).then((data) => {
        this.trackingUrl.setData(data)
        this.editUrlDialog = true
      })
    },
    showOverlayUrlPreview () {
      EventBus.$emit('showOverlayUrlPreview')
    },

    showOverlayUnsubscribe () {
      this.resetUnsubscribe = false
      EventBus.$emit('showOverlayUnsubscribe')
      setTimeout(() => {
        this.resetUnsubscribe = true
      })
    },
    async showOverlayPreviewForm () {
      const user = this.$store.getters['auth/getUser']
      this.formDataTable.previewUrl = await getEnv('backend_endpoint') + 'api/forms/preview/' + this.formId + '?token=' + user.token
      this.formDataTable.preview = true
      EventBus.$emit('showOverlayFormPreview')
      this.updateFormPreview()
    },
    updateFormPreview () {
      EventBus.$emit('smsUpdatedForm')
      this.showFormPreviewFlag = false
      setTimeout(() => {
        this.showFormPreviewFlag = true
      })
    },
    closeFormsEditor () {
      EventBus.$emit('closeOverlayFormEditor')
    },
    showOverlayFormEditor () {
      EventBus.$emit('showOverlayFormEditor')
    },
    saveState () {
      this.state.setState(this.$data)
    },
    recoverState () {
      this.state.getState(this.$data)
    },
    saveAndExit () {
    },
    setInvalidElements (invalid) {
      this.saveBarProperties.invalid = invalid
    },
    updatePreview (data) {
      this.preview = new SmsPreview(data)
      this.landingTemplate.customVars = this.fields.availableFields
      EventBus.$emit('hideAlert')
    },
    updateContactsFileUploadResult (data) {
      this.contactsFileUploadResult = new ContactsFileUploadResponse(data)
      EventBus.$emit('hideAlert')
    },
    save (redirect = false) {
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED
      return this.persist(this.urls.submitUrl, redirect)
    },
    saveAndGetCost (redirect = false) {
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED
      const data = this.campaign.formatData()
      CampaignService.persist(data, this.urls.submitUrl).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.campaign.sendings[0].recipientsTest = []
          this.setEditUrl()
          EventBus.$emit('showLoading', true, this.$t('Calculando el coste de la campaña...'))
          CampaignService.getCampaignCost(this.campaign.id)
          .then(
            (response) => {
              this.$set(this.campaign, 'campaignCosts', new CampaignCosts())
              this.campaign.campaignCosts.load(response)
            },
            () => {},
          )
          .finally(() => {
            EventBus.$emit('showLoading', false)
          })
        },
      )
    },
    saveAndBalance () {
      this.routeLeave = false
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED
      const data = this.campaign.formatData()
      CampaignService.persist(data, this.urls.submitUrl).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.campaign.sendings[0].recipientsTest = []
          EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado su campaña correctamente'))
          this.$router.push({
            name: 'addPayment',
            query: {
              campaign_id: this.campaign.id,
              campaign_type: 'sms',
              campaign_sub_type: this.campaign.subtype,
            },
          })
        },
        (error) => {
          if (error.response.status === 422) {
            let errorsAlert = ''
            const errors = error.response.data
            for (const index in errors) {
                // eslint-disable-next-line max-depth
                if (errors[index].length) {
                  errorsAlert += errors[index][0]
                } else {
                  errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
                }
            }
            return EventBus.$emit('showAlert', 'danger', errorsAlert)
          }
          if (error.response.status === 400) {
            return EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
          }

          if (error.response.data.balanceAlert) {
            return this.showBalanceAlert(error.response.data.isScheduled)
          }
          this.showErrorAlert(this.$t('Ocurrió un error al crear la campaña'))
        },
      )
    },
    send () {
      this.routeLeave = false
      const contactSource = this.$refs.contactSource
      if (contactSource.validate()) {
        this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
        if (this.campaign.sendings[0].sendingDate) {
          this.campaign.sendings[0].statusId = SendingStatuses.PENDING
        } else {
          this.campaign.sendings[0].statusId = SendingStatuses.PREPARING
        }
        if (this.urls.submitUrl === this.urls.store) {
          this.urls.submitUrl = this.urls.send
        }
        this.isSendingCampaign = true
        return this.persist(this.urls.submitUrl, true)
      }
    },
    toggleValidateModal () {
      this.showValidateModal = !this.showValidateModal
    },
    acceptedValidateModal () {
      this.routeLeave = false
      this.loadingValidateModal = true
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED

      const data = this.campaign.formatData()
      CampaignService.persist(data, this.urls.submitUrl).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.campaign.sendings[0].recipientsTest = []
          this.setEditUrl()

          const data = {
            sendingId: response.sending.id,
            campaignId: response.sending.campaign_id,
            type: this.campaign.subtype,
          }
          CampaignService.smsSendingChangeStatusValidating(data).then(
            () => {
              EventBus.$emit('showAlert', 'success', this.$t('Campaña actualizada correctamente'))
              this.$router.push({ name: 'HomePage' })
            },
            () => {
              EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al validar la campaña'))
            },
          ).finally(() => {
            this.loadingValidateModal = false
            this.toggleValidateModal()
          })
        },
        (error) => {
          if (error.response.status === 422) {
            let errorsAlert = ''
            const errors = error.response.data
            for (const index in errors) {
                // eslint-disable-next-line max-depth
                if (errors[index].length) {
                  errorsAlert += errors[index][0]
                } else {
                  errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
                }
            }
            return EventBus.$emit('showAlert', 'danger', errorsAlert)
          }
          if (error.response.status === 400) {
            return EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
          }

          if (error.response.data.balanceAlert) {
            return this.showBalanceAlert(error.response.data.isScheduled)
          }
          this.showErrorAlert(this.$t('Ocurrió un error al crear la campaña'))
        },
      )
    },
    persist (url, redirect = false) {
      const data = this.campaign.formatData()
      CampaignService.persist(data, url)
        .then(
          async (response) => {
            if (response.error) {
              EventBus.$emit('showAlert', 'danger', this.$t(response.error_message))
              this.confirmCampaignExit = true
              this.$router.push({ path: this.urls.redirectUrl })
              EventBus.$emit('flashCampaign', this.campaign)
            } else {
              if (redirect) {
                this.$router.push({ path: this.urls.redirectUrl })
              } else {
                this.campaign.id = response.sending.campaign_id
                this.campaign.sendings[0].channel.id = response.sending.channel_id
                this.campaign.sendings[0].recipientsTest = []
                this.setEditUrl()
              }
              this.lastCampaignHash = await sha256(JSON.stringify(this.campaign))
              let message = this.isSendingTest
                ? this.$t('Se ha enviado la prueba correctamente')
                : this.$t('Se ha guardado su campaña correctamente')
              message = this.isSendingCampaign
                ? this.$t('Se ha creado su campaña correctamente')
                : message
              EventBus.$emit('showAlert', 'success', message)
              this.isSendingTest = false
              this.isSendingCampaign = false
            }
          },
          (error) => {
            console.log(error.response)
            if (error.response.status === 422) {
              let errorsAlert = ''
              const errors = error.response.data
              for (const index in errors) {
                // eslint-disable-next-line max-depth
                if (errors[index].length || errors[index].messages?.length) {
                  errorsAlert += errors[index].messages[0] ?? errors[index][0] + '<br>'
                } else {
                  errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
                }
              }
              return EventBus.$emit('showAlert', 'danger', errorsAlert)
            }
            if (error.response.status === 400) {
              return EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
            }

            if (error.response.data.balanceAlert) {
              return this.showBalanceAlert(error.response.data.isScheduled)
            }
            this.showErrorAlert(this.$t('Ocurrió un error al crear la campaña'))
          },
        )
    },
    showErrorAlert (errors) {
      EventBus.$emit('showAlert', 'danger', errors)
    },

    showBalanceAlert (isScheduled) {
      if (isScheduled) {
        EventBus.$emit('balanceAlertScheduled')
      } else {
        EventBus.$emit('balanceAlert')
      }
    },

    saveAndRedirectToPayment () {
      this.$router.push(this.urls.paymentUrl)
      this.campaign.costs.skipValidateBalance = true
      return this.save()
    },

    confirmScheduled () {
      this.campaign.costs.skipValidateBalance = true
      return this.send()
    },

    setFlashSms (value) {
      this.campaign.sendings[0].channel.flash = value
    },

    sendTest () {
      if (this.preview.totalRecipients === 0) {
        return
      }
      this.$bvModal.show('testModal')
    },

    setDefaultCampaignData () {
      const date = moment().format('YYYY-MM-DD-HH:mm:ss')
      this.campaign.name = 'SMS' + '-' + date
      this.campaign.sendings[0].sendingTimezone = this.componentsData.defaultTimezone
      this.setType()
    },

    setType () {
      this.campaign.type = this.campaign.subtype === CampaignType.automatic.subtypes.meet || this.campaign.subtype === CampaignType.automatic.subtypes.birthday
        ? CampaignType.automatic.id
        : CampaignType.basic.id
    },

    refreshPreviewCosts () {
      if (this.preview.totalRecipients === 0) {
        return
      }
      const contactSource = this.$refs.contactSource
      if (this.campaign.recipientsSource === this.sourceKeys.IMPORT_KEY || this.campaign.recipientsSource === this.sourceKeys.IMPORT_COPY_PASTE_KEY) {
        contactSource.reparseFile()
      } else {
        contactSource.loadPreviewData()
      }
    },

    showAllGroups (data) {
      this.componentsData.groups = data
    },

    setReady () {
      this.readyStatus.ready = false
      setTimeout(() => {
        this.readyStatus.ready = true
      })
    },

    saveAndSendTest () {
      this.isSendingTest = true
      EventBus.$emit('close-test-dialogs')
      this.campaign.sendings[0].recipientsTest = this.phonesWithPrefix.phonesWithPrefix
      return this.save(false)
    },

    setEditUrl () {
      this.urls.submitUrl = this.urls.update + '/' + this.campaign.id
    },

    showPanels () {
      if (this.campaign.sendings[0].sendingDate || this.campaign.sendings[0].expirationDate) {
        this.panel = [0]
      }
      if (
        this.campaign.sendings[0].tags.length > 0 ||
        this.campaign.sendings[0].batchMinutes ||
        this.campaign.sendings[0].channel.certified ||
        this.campaign.sendings[0].channel.flash
      ) {
        if (this.panel !== null) {
          this.panel = [0, 1]
        } else {
          this.panel = [1]
        }
      }
    },

    cancelSendLater () {
      this.panel.splice(0, 1)
    },

    setLanding (landing, landingTemplate) {
      this.isLandingMessage = true
      this.landing = landing
      this.landingTemplate = landingTemplate
      this.$refs.secondSection.addFieldToMessage('{LAND_URL}')
    },

    async updateLandingPreview () {
      // this.landing.htmlContent = this.landingTemplate.htmlContent
      // this.landing.type = this.landingTemplate.type
      EventBus.$emit('smsCampaginShowingLanding', false)
      this.campaign.sendings[0].channel.templateStructure = this.landingTemplate.structure
      const data = {
        structure: this.landingTemplate.structure,
        typeLanding: 'Email',
      }

      LandingTemplateService.getPreviewFromStructure(data)
        .then(
          (response) => {
            this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.preview)
          },
          () => { },
        )
    },

    getPreview () {
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showPreviewFromStructure(this.landingTemplate.structure)
      })
    },
    async loadGroups () {
      EventBus.$emit('showLoading', true, this.$t('Cargando grupos...'))
      this.componentsData.loadingGroups = true
      if (this.nextGroupsPage > this.lastGroupsPage) {
        this.componentsData.loadingGroups = false
        EventBus.$emit('showLoading', false)
        return
      }
      if (this.nextGroupsPage === 1) {
        this.componentsData.groups = []
      }
      let groups = []
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic) {
        groups = await groupService.getWithContactsSendingsByChannel({
          channelType: this.channelType,
          page: this.nextGroupsPage,
        })
      } else {
        groups = await groupService.getWithContactsSendingsByChannelWithDates({
          channelType: this.channelType,
          page: this.nextGroupsPage,
        })

        const response = await UserService.getAvailableFieldsForGroupsAdvancedByType({
          groups: [
            ...this.componentsData.groups,
            ...groups.data.map(g => g.id),
          ],
        })
        this.fields.availableDateFields = response.availableFieldsForGroupsAdvancedByType?.custom?.items?.filter(field => field.type === 'date')
          .reduce((obj, field) => ({ ...obj, [field.contacts_group_id]: { [field.name]: field.name } }), {})
      }
      this.lastGroupsPage = groups.last_page
      this.groupsTotal = groups.total
      this.componentsData.groups = [...this.componentsData.groups, ...groups.data]
      this.nextGroupsPage++
      this.componentsData.groups = this.componentsData.groups.filter(ar => !this.selectedGroups.find(rm => rm.id === ar.id))
      this.componentsData.loadingGroups = false
      EventBus.$emit('showLoading', false)
    },
    setAvailableFields (from, data, headers = null) {
      let fields = data
      if (from === 'file') {
        fields = {}
        for (let i = 1; i <= data[0].length; i++) {
          const header = headers[i - 1] ? ' (' + headers[i - 1] + ')' : ''
          fields['COL' + i] = 'COL' + i + ' - ' + data[0][i - 1] + header
        }
      }
      this.fields.availableFields = fields
    },
    async loadAllGroups () {
      EventBus.$emit('showLoading', true, this.$t('Cargando grupos...'))
      const groups = await groupService.getAllGroups({
        channelType: this.channelType,
      })
      EventBus.$emit('showLoading', false)
      this.componentsData.groups = [...groups]
    },
    onCleanSelectedGroups () {
      this.selectedGroups = []
      this.componentsData.groups = [...this.componentsData.groups]
    },
    onCheckShowDrawer (recipientsSource) {
      this.saveState()
      EventBus.$emit('contactSource:saveState')
      if (!this.isSameSource(recipientsSource)) {
        if (this.preview.totalRecipients > 0) {
          this.nextRecipientsSource = recipientsSource
          this.changeRecipientsSource = true
        } else {
          this.openDrawer(recipientsSource)
        }
      } else {
        this.openDrawer(recipientsSource)
      }
    },
    isSameSource (recipientsSource) {
      return this.campaign.recipientsSource === recipientsSource
    },
    openDrawer (recipientsSource) {
      this.campaign.recipientsSource = recipientsSource
      EventBus.$emit('contactSource:showDrawer', recipientsSource)
    },
    confirmOpenDrawer () {
      EventBus.$emit('contactSource:cleanDrawer')
      EventBus.$emit('contactSource:resetDrawer')
      this.onCleanSelectedGroups()
      this.preview = new SmsPreview()
      this.changeRecipientsSource = false
      this.saveState()
      this.openDrawer(this.nextRecipientsSource)
      this.clearAvailableFieldsMessage()
    },
    cancelOpenDrawer () {

    },
    openAiAssistant () {
      this.aiDrawerCtrl.show = true
    },
    clearAvailableFieldsMessage () {
      if (this.fields.currentFieldsMessage.length > 0) {
        for (const currentField of this.fields.currentFieldsMessage) {
          let message = this.campaign.sendings[0].channel.message
          const current = new RegExp('{' + currentField + '}', 'g')
          message = message.replace(current, '')
          this.campaign.sendings[0].channel.message = message
        }
      }

      this.preview.contactPreview = 0
    },
    setCurrentSmsEncodingByCountryIso () {
      this.readySecondSection = false
      if (this.campaign.recipientsSource === 'contacts' && this.campaign.groups.length) {
        groupService.getAllContactsCountries({
          groups: this.campaign.groups,
        }).then(countries => {
          const canGSMPT = ['BR', 'PT', 'AO', 'MO', 'GQ', 'CV'].includes(countries[0])
          if (countries.length === 1 && canGSMPT) {
            this.$refs.secondSection.extraEncodings = ['GSM-PT']
            this.currentSms = new CurrentSms('GSM-PT', this.currentSms.characters, this.currentSms.chunks, this.currentSms.maxCharactersInfo)
          } else {
            this.$refs.secondSection.extraEncodings = []
            this.currentSms = new CurrentSms('GSM', this.currentSms.characters, this.currentSms.chunks, this.currentSms.maxCharactersInfo)
          }
        })
      } else {
        if (['BR', 'PT', 'AO', 'MO', 'GQ', 'CV'].includes(this.campaign.sendings[0].csvFileConfig.countryIso)) {
          this.$refs.secondSection.extraEncodings = ['GSM-PT']
          this.currentSms = new CurrentSms('GSM-PT', this.currentSms.characters, this.currentSms.chunks, this.currentSms.maxCharactersInfo)
        } else {
          this.$refs.secondSection.extraEncodings = []
          this.currentSms = new CurrentSms('GSM', this.currentSms.characters, this.currentSms.chunks, this.currentSms.maxCharactersInfo)
        }
      }
      setTimeout(() => {
        this.readySecondSection = true
      })
    },
    closeAcceptDrawer () {
      this.setCurrentSmsEncodingByCountryIso()
      this.saveState()
      EventBus.$emit('contactSource:saveState')
    },
    closeCancelDrawer () {
      this.recoverState()
      if (this.preview.totalRecipients === 0) {
        EventBus.$emit('contactSource:resetDrawer')
        this.onCleanSelectedGroups()
      } else {
        EventBus.$emit('contactSource:recoverState')
      }
    },
    isSelectedRecipientsSource (recipientsSource) {
      return this.campaign.recipientsSource === recipientsSource
    },
    setSelectedGroups () {
      this.selectedGroups = [...this.componentsData.groups]
    },
    checkAndSetEventDate () {
      const programCampaignDate = localStorage.getItem('programCampaignDate')
      if (programCampaignDate && this.campaign.subtype === 'basic') {
        this.panel = [0]
        this.campaign.sendings[0].sendingDate = programCampaignDate
      }
      localStorage.removeItem('programCampaignDate')
    },
    updateSelectedGroups (newGroups) {
      this.selectedGroups = newGroups
      this.campaign.groups = newGroups.map(g => g.id)
      if (this.campaign.recipientsSource === this.sourceKeys.CONTACTS_KEY) {
        if (newGroups.length) {
          this.preview.totalRecipients = newGroups.reduce((prev, curr) => {
            return prev + curr.contacts
          }, 0)
        } else {
          this.preview.totalRecipients = 0
        }
      }
    },
    loadDefaults () {
      this.loadGroups()
      this.showSendLater = true
      this.setDefaultCampaignData()
      this.urls.submitUrl = this.urls.store
      this.checkAndSetEventDate()
      this.setReady()
    },

    async showFormPreview (formId) {
      this.formId = formId
      const user = this.$store.getters['auth/getUser']
      this.formPreviewUrl = await getEnv('backend_endpoint') + 'api/forms/preview/' + formId + '?token=' + user.token
      this.showForm = true
    },
    async showFormPreviewEdit (formId) {
      this.formId = formId
      const user = this.$store.getters['auth/getUser']
      this.formPreviewUrl = await getEnv('backend_endpoint') + 'api/forms/preview/' + formId + '?token=' + user.token
      EventBus.$emit('showOverlayFormEditor')
    },
    async showLandingPreviewEdit () {
      EventBus.$emit('showOverlayEditor')
    },
    async showUrlPreview (urlId) {
      this.urlId = urlId
      this.urlPreviewUrl = this.componentsData.availableUrls[urlId].url_preview
      this.showUrl = true
    },
    async showUnsubscribePreview (urlId) {
      this.unsubscribePageId = urlId
      this.showUnsubscribePreviewIframe = false
      UnsuscribeService.getPage({ type: 'sms', id: urlId }).then((page) => {
        UnsuscribeService.preview(page.unsubscribePage).then(
          (response) => {
            this.unsubscribePreview = response.page
            this.showUnsubscribePreviewIframe = true
          },
          () => {},
        )
      })

      this.showUnsubscribe = true
    },
    showOverlayUnsubscribeEditorPreview () {
      this.showOverlayUnsubscribe()
      setTimeout(() => {
        EventBus.$emit('showUnsubscribePreviewInEditor')
      })
    },
    setSender () {
        const verifiedBrandId = this.$route.params.verifiedBrandId
        if (!verifiedBrandId) {
            return
        }
        VerifiedSmsService.getVerifiedSenders({ verifiedBrandId })
        .then(
            (response) => {
            this.campaign.sendings[0].channel.sender = response.sender
            },
            () => {},
        )
    },
    closeCampaignExit () {
      this.showCampaignExit = false
      this.campaignExit = false
    },
    saveCampaignExit () {
      this.showCampaignExit = false
      this.confirmCampaignExit = true
      this.save()
      this.$router.push(this.urlCampaignExit.fullPath)
    },
    noSaveCampaignExit () {
      this.showCampaignExit = false
      this.confirmCampaignExit = true
      this.$router.push(this.urlCampaignExit.fullPath)
    },
    async openCampaignExit (to) {
      this.$refs.secondSection.$refs.senders.$refs.sendercombobox.blur()
      const currentHash = await sha256(JSON.stringify(this.campaign))
      await this.$refs.campaignValidation.validate()
      if (this.isValidSave === true && this.lastCampaignHash !== currentHash) {
      this.showCampaignExit = true
      this.urlCampaignExit = to
      } else {
        this.confirmCampaignExit = true
        this.$router.push(to.fullPath)
      }
    },
    messageValidateSelected (smsValidateDlt) {
      this.campaign.sendings[0].channel.sender = smsValidateDlt.sender
      this.campaign.sendings[0].channel.message = smsValidateDlt.template_content
    },
    validateCampaign () {
      this.$refs.campaignValidation?.validate()
    },
    showMultimedia () {
      EventBus.$emit('showMultimedia', 'landings', true)
    },
  },
  computed: {
    canUseCustomSender () {
      if (['CO', 'BR'].indexOf(this.campaign.countryIso) < 0) {
        return true
      }

      return false
    },
    isValidDlt: function () {
      const user = this.$store.getters['auth/getUser']
      const isoCountry = user.isoCountry?.toUpperCase() || user.isoTld?.toUpperCase()

      return this.countryIsoValidateSms.includes(isoCountry) && this.countryIsoValidateSms.includes(this.campaign.countryIso)
    },
    isValidSendTest: function () {
      return (
        this.campaign.sendings[0].channel.sender !== '' &&
        this.campaign.sendings[0].channel.message !== '' &&
        !this.saveBarProperties.invalid
      )
    },
    isValidSave: function () {
      return (
        this.campaign.sendings[0].channel.sender !== '' &&
        this.campaign.sendings[0].channel.message !== '' &&
        !this.tooManyShippingHours &&
        !this.saveBarProperties.invalid
      )
    },
    isValidSend: function () {
      return (
        this.preview.totalRecipients !== 0 &&
        this.campaign.sendings[0].channel.sender !== '' &&
        this.campaign.sendings[0].channel.message !== '' &&
        !this.tooManyShippingHours &&
        !this.saveBarProperties.invalid
      )
    },
    getShippingTotalHours () {
      const minutes = this.campaign.sendings[0].batchParts * this.campaign.sendings[0].batchMinutes
      return Math.floor(minutes / 60).toFixed(0)
    },
    tooManyShippingHours () {
      return this.getShippingTotalHours >= 48
    },
  },
  mounted () {
    EventBus.$emit('showLoading', true)
    const campaignId = this.$route.params.id
    this.user = this.$store.getters['auth/getUser']
    this.campaign.sendings[0].channel_type_id = ChannelType.SMS_ID

    this.$nextTick(function () {
      const showMultimediaLanding = this.$router.currentRoute.query.landing
      if (showMultimediaLanding !== undefined) {
        EventBus.$emit('showMultimedia', 'landings', false)
      }
      const showPolls = this.$router.currentRoute.query.poll
      if (showPolls !== undefined) {
        EventBus.$emit('showMultimedia', 'formularios', false)
      }
    })

    if (campaignId) {
      CampaignService.getSmsEditCampaignData(campaignId, this.$router.currentRoute.query.type)
        .then(
          async (response) => {
            this.componentsData = response.componentsData
            this.campaign.load(response.campaign)
            this.setSelectedGroups()
            this.loadGroups()
            if (!campaignId || !this.campaign.countryIso) {
              this.campaign.sendings[0].csvFileConfig.setCountry(this.user.isoCountry.toUpperCase() || this.user.isoTld.toUpperCase())
            }
            this.setEditUrl()
            this.preview = new SmsPreview(response.preview)
            this.contactsFileUploadResult = new ContactsFileUploadResponse(response.contactsFileUploadResult)
            this.campaign.costs.sendingCost = this.preview.sendingCost
            this.fields.availableFields = response.availableFields

            // landing
            if (response.landing.id) {
              this.isLandingMessage = true
              this.landing = response.landing
              this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(this.landing.preview)
              this.landingTemplate.structure = this.campaign.sendings[0].channel.templateStructure
              this.landingTemplate.customVars = this.fields.availableFields
              this.landingTemplate.setEditorHelper()
            }
            this.showPanels()
            this.setType()
            this.setReady()
            this.lastCampaignHash = await sha256(JSON.stringify(this.campaign))
            EventBus.$emit('showLoading', false)
          },
          (error) => {
            if (error.response.data.message === 'no_edit_campaign') {
              const message = this.$t('No puedes editar esta campaña')
              this.showErrorAlert(message)
              const that = this
              EventBus.$emit('showLoading', false)
              setTimeout(function () {
                that.$router.go(-1)
              }, 1000)
            }
          },
        )
    } else {
      CampaignService.getSmsCreateData(this.$router.currentRoute.query.type)
        .then(
          (response) => {
            this.componentsData = new SmsComponentsData(response.componentsData)
            this.campaign.subtype = response.campaign.subtype
            this.loadDefaults()
            this.setSender()
            EventBus.$emit('showLoading', false)
          },
        )
    }

    EventBus.$on('changedLanguage', () => {
      CountryService.getUserCountries().then(
        (response) => {
          this.componentsData.countries = response.countries
        },
      )
    })

    EventBus.$on('getUpdatedConctactFields', () => {
      EventBus.$emit('updateConctactFields', this.fields)
    })

    EventBus.$on('validate-campaign-sender', this.validateCampaign)
    EventBus.$off('hook:unmounted', this.validateCampaign)
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else if (this.routeLeave === false) {
      next()
    } else if (this.confirmCampaignExit) {
      next()
    } else {
      this.openCampaignExit(to)
      next(false)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else if (this.routeLeave === false) {
      next()
    } else if (this.confirmCampaignExit) {
      next()
    } else {
      this.openCampaignExit(to)
      next(false)
    }
  },
}
